import React, { Component } from "react";
import { Form, Layout, Modal, Spin, Row, Col, Radio, Button, Icon, Input } from "antd";
import "./index.scss";
import moment from "moment";
import Countdown from "react-countdown";
import RegistrationModal from "./components/RegistrationModal";
import PopupModal from "./components/PopupModal";
import DisclaimerModal from "./components/DisclaimerModal";
import { deserialize } from "../../utils/helper";

const { Content } = Layout;

class Event extends Component {
  initialState = {
    eventData: {},
    isLoadingState: false,
    selectedTicket: null,
    showRegistrationModal: false,
    showPopupModal: true,
    showDisclaimerModal: false,
    showWarning: false,
    ticket: {},
    tickets: [],
    purchaseCount: 0,
    selectedTickets: []
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {
    const eventName = this.props.match.params.event;
    const params = {
      slug: eventName
    };
    this.props.onGetEventBySlug(params);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.eventData !== nextProps.eventData) {

      const queryString = deserialize(this.props.location.search);
      const ticketId = queryString.ticket ? queryString.ticket.split(".")[0] : null;

      let ticket = {};

      if (nextProps.eventData.tickets) {
        nextProps.eventData.tickets.forEach(row => {
          if (row.id == ticketId) {
            ticket = row;
          }
          row.purchase_count = 0;
        });
      }

      this.setState({
        eventData: nextProps.eventData,
        isLoadingState: true,
        selectedTicket: ticketId,
        ticket: ticket,
        tickets: nextProps.eventData.tickets,
      })
    }
  }

  handleSelectTicket = (e) => {
    let ticket = {};

    this.state.eventData.tickets.forEach(row => {
      if (row.id == e.target.value) {
        ticket = row;
      }
    });

    this.setState({
      selectedTicket: e.target.value,
      ticket: ticket,
      showWarning: false
    });
  }

  handleJoinButton = () => {
    const { purchaseCount, tickets } = this.state;
    // if (this.state.selectedTicket) {
    if (purchaseCount > 0) {
      let selectedTickets = [];
      tickets.forEach(row => {
        if (row.purchase_count > row.max_avail) {
          return false;
        }
        if (row.purchase_count > 0) {
          selectedTickets.push(row);
        }
      });

      this.setState({
        // showRegistrationModal: true,
        showDisclaimerModal: true,
        showWarning: false,
        selectedTickets: selectedTickets
      });
    } else {
      this.setState({
        showWarning: true
      });
    }
  }

  handleCloseRegistrationModal = () => {
    this.setState({
      showRegistrationModal: false,
    });
  }

  handleClosePopupModal = () => {
    this.setState({
      showPopupModal: false,
    });
  }

  handleCloseDisclaimerModal = () => {
    this.setState({
      showDisclaimerModal: false,
    });
  }

  handleAgreeButton = () => {
    this.setState({
      showDisclaimerModal: false,
      showRegistrationModal: true
    });
  }

  handleTicketCountChange = (e, id) => {
    const tickets = this.state.tickets;
    let purchaseCount = 0;

    tickets.forEach(row => {
      if (row.id === id && parseInt(row.max_avail) >= parseInt(e.target.value)) {
        row.purchase_count = parseInt(e.target.value);
      }

      purchaseCount += parseInt(row.purchase_count);
    });

    this.setState({ ...tickets, purchaseCount: purchaseCount });
  }

  render() {
    // const { id } = useParams();
    const { eventData, isLoadingState, selectedTicket, showRegistrationModal, showWarning, showPopupModal, showDisclaimerModal, ticket, tickets, selectedTickets } = this.state;
    const { isLoading } = this.props;

    const isDoneLoading = !isLoading && isLoadingState;

    const radioStyle = {
      display: 'block',
      lineHeight: '30px',
      marginBottom: '10px',
      fontWeight: "bold"
    };

    const queryString = deserialize(this.props.location.search);
    const ticketId = queryString.ticket ? queryString.ticket.split(".")[0] : null;

    if (eventData.page_details === undefined) {
      return (
        <Spin spinning={!isDoneLoading}>
          <Layout className="" style={{ minHeight: "100vh", minWidth: "100%" }}>
            &nbsp;
          </Layout>
        </Spin>
      )
    }

    let countdown = "The Event has Ended";
    let eventEnded = false;
    if (eventData.start_diff > 0) { // not started
      countdown = (<Countdown
        date={Date.now() + (eventData.start_diff * 1000)}
        intervalDelay={0}
        renderer={(e) => {
          JSON.stringify(e);
          const format =
            e.days + "d " + e.hours + "h " + e.minutes + "m " + e.seconds + "s";
          return format;
        }}
        precision={3}
      />);
    } else if (eventData.end_diff > 0) { // ongoing
      countdown = "Event is ongoing";
    } else { // ended
      countdown = "Event ended";
      eventEnded = true;
    }

    const pageDetails = eventData.page_details;
    const primaryColor = pageDetails.primary_color;
    const primaryFontColor = pageDetails.primary_font_color;
    const secondaryColor = pageDetails.secondary_color;
    const secondaryFontColor = pageDetails.secondary_font_color;
    return (
      <Spin spinning={!isDoneLoading}>
        <Layout className="" style={{ padding: "10px", backgroundColor: "#FAFAFA", minHeight: "100vh" }}>
          <Content>
            <Row justify="center" gutter={30} style={{ maxWidth: "1000px", marginLeft: "auto", marginRight: "auto" }}>
              <Col span={24} style={{ padding: 5 }}>
                <Row style={{ margin: "10px 5px 20px 5px" }} gutter={30}>
                  <Col xs={0} sm={4} style={{
                    height: "50px",
                    width: "50px",
                    lineHeight: "50px",
                    position: "relative",
                    padding: "0"
                  }}>
                    <img src={pageDetails.display_photo ? `${process.env.REACT_APP_S3_BUCKET}${pageDetails.display_photo}` : ''}
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        verticalAlign: "top"
                      }}
                    />
                  </Col>
                  <Col xs={0} sm={20}>
                    <h2>{eventData.event_name}</h2>
                    {pageDetails.enable_date_time == 1 ? (
                      <div>
                        <Icon type="schedule" />
                        &nbsp;&nbsp;
                        <span>
                          <b>{`${moment(eventData.event_date_start).format("MMM. DD")}, ${eventData.event_time_start}`}</b> to <b>{`${moment(eventData.event_date_end).format("MMM. DD")}, ${eventData.event_time_end}`}</b>
                        </span>
                        &nbsp;
                        <span>
                          {eventData.timezone}
                        </span>
                      </div>
                    ) : ''}
                    {pageDetails.enable_address == 1 && (eventData.address1 || eventData.address2 || eventData.address3) ? (
                      <div>
                        <Icon type="pushpin" />
                        &nbsp;&nbsp;
                        <span>
                          <b>{`${eventData.address1 ?? ''} ${eventData.address2 ?? ''} ${eventData.address3 ?? ''}`}</b>
                        </span>
                      </div>
                    ) : ''}

                  </Col>

                  <Col xs={6} sm={0} style={{
                    height: "50px",
                    width: "50px",
                    lineHeight: "50px",
                    position: "relative",
                    padding: "0"
                  }}>
                    <img src={pageDetails.display_photo ? `${process.env.REACT_APP_S3_BUCKET}${pageDetails.display_photo}` : ''}
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        verticalAlign: "middle"
                      }}
                    />
                  </Col>
                  <Col xs={18} sm={0} style={{
                    height: "50px",
                    position: "relative",
                    lineHeight: "50px",
                    padding: "0 10px"
                  }}>
                    <h2 style={{ verticalAlign: "middle" }}>{eventData.event_name}</h2>
                  </Col>
                  <Col xs={24} sm={0} style={{ padding: 0 }}>
                    {pageDetails.enable_date_time == 1 ? (
                      <div style={{ fontSize: ".8rem" }}>
                        <Icon type="schedule" />
                        &nbsp;&nbsp;
                        <span>
                          <b>{`${moment(eventData.event_date_start).format("MMM. DD")}, ${eventData.event_time_start}`}</b> to <b>{`${moment(eventData.event_date_end).format("MMM. DD")}, ${eventData.event_time_end}`}</b>
                        </span>
                        &nbsp;
                        <span>
                          {eventData.timezone}
                        </span>
                      </div>
                    ) : ''}
                    {pageDetails.enable_address == 1 && (eventData.address1 || eventData.address2 || eventData.address3) ? (
                      <div>
                        <Icon type="pushpin" />
                        &nbsp;&nbsp;
                        <span>
                          <b>{`${eventData.address1 ?? ''} ${eventData.address2 ?? ''} ${eventData.address3 ?? ''}`}</b>
                        </span>
                      </div>
                    ) : ''}

                  </Col>
                </Row>

                <Row style={{ margin: "5px 5px 20px 5px" }}>
                  <Col span={24} style={{
                    // backgroundColor: "#F5F5F5",
                    backgrounColor: "transparent",
                    // minHeight: "300px",
                    // lineHeight: "300px",
                    position: "relative",
                    // borderRadius: "10px",
                    textAlign: "center",
                    margin: "20px, 0",
                  }}>
                    <img src={pageDetails.cover_photo ? `${process.env.REACT_APP_S3_BUCKET}${pageDetails.cover_photo}` : ''}
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        verticalAlign: "middle",
                        borderRadius: "10px"
                      }}
                    />
                  </Col>
                </Row>

                <Row style={{ margin: "5px 5px 20px 5px" }} gutter={10}>
                  <Col xs={24} sm={14} style={{ padding: "10px", backgroundColor: "#FFF", borderRadius: "5px", border: "solid 1px #F5F5F5" }}>
                    <div dangerouslySetInnerHTML={{ __html: eventData.page_details.details }} />
                  </Col>

                  <Col xs={24} sm={10}>
                    <Col span={24} style={{
                      borderRadius: "5px",
                      marginBottom: "10px",
                      textAlign: "center",
                      padding: "10px",
                      backgroundColor: primaryColor,
                      color: primaryFontColor,
                      fontSize: "1.1rem",
                      fontWeight: "bold"
                    }}>
                      {pageDetails.enable_countdown == 1 ? (
                        <span>{countdown}</span>
                      ) : (<span>&nbsp;</span>)}
                    </Col>

                    <Col span={24} style={{ borderRadius: "5px", padding: "10px", border: "solid 1px #F5F5F5", backgroundColor: "#FFF" }}>
                      <Col span={24} style={{ fontWeight: "bold", marginBottom: "10px" }}>
                        Tickets <br />
                        {showWarning ? (<span style={{ color: "red" }}>Please select a ticket</span>) : ''}
                      </Col>

                      <Col span={24}>
                        {tickets.map(ticket => (
                          <Row gutter={10} key={ticket.id} style={{ marginBottom: "10px" }}>
                            <Col span={14}>
                              <span style={{ fontWeigt: "bold", }}>{ticket.ticket_name}</span><br />
                              {
                                ticket.description ?
                                  <span style={{ fontWeight: "100", fontSize: ".9rem", fontStyle: "italic" }}>{ticket.description}</span>
                                  : ''
                              }
                            </Col>
                            <Col span={6}>{ticket.price == 0 ? "FREE" : "P" + ticket.price}</Col>
                            <Col span={4}>
                              <Input type="number"
                                onChange={e => this.handleTicketCountChange(e, ticket.id)}
                                initialvalue={ticket.purchase_count}
                                disabled={ticketId !== null && ticketId != ticket.id} checked={ticketId !== null && ticketId == ticket.id}
                                min={0}
                                max={ticket.is_multiple_email == 1 ? (ticket.issue_count - ticket.avail_count >= ticket.max_avail ? ticket.max_avail : ticket.issue_count - ticket.avail_count) : 1}
                              />
                            </Col>
                          </Row>
                        ))}

                        {/* <Radio.Group onChange={this.handleSelectTicket} disabled={eventEnded} defaultValue={ticketId}>
                          {eventData.tickets.map(ticket => (
                            <Radio value={ticket.id} key={ticket.id} style={radioStyle} disabled={ticketId !== null && ticketId != ticket.id} checked={ticketId !== null && ticketId == ticket.id}>
                              <span style={{ fontWeigt: "bold", }}>{ticket.ticket_name} - {ticket.price == 0 ? "FREE" : "P" + ticket.price}</span><br />
                              {
                                ticket.description ?
                                  <span style={{ fontWeight: "100", fontSize: ".9rem", fontStyle: "italic" }}>{ticket.description}</span>
                                  : ''
                              }
                            </Radio>
                          ))}
                        </Radio.Group> */}
                      </Col>
                      <Col span={24} style={{ textAlign: "center" }}>
                        <Button style={{ backgroundColor: primaryColor, color: primaryFontColor, fontSize: "1.1rem", fontWeight: "bold", padding: "10px 30px", height: "auto" }} onClick={this.handleJoinButton} disabled={eventEnded} readOnly={eventEnded}>
                          {pageDetails.join_button_text}
                        </Button>
                      </Col>
                    </Col>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Content>
        </Layout>

        <RegistrationModal event={eventData} pageDetails={pageDetails} selectedTickets={selectedTickets} ticket={ticket} selectedTicket={selectedTicket} showRegistrationModal={showRegistrationModal} registrationFields={eventData.registration_fields} handleCloseRegistrationModal={this.handleCloseRegistrationModal} {...this.props} />

        <PopupModal pageDetails={pageDetails} showPopupModal={pageDetails.enable_pop == 1 && showPopupModal} handleClosePopupModal={this.handleClosePopupModal} {...this.props} />
        <DisclaimerModal pageDetails={pageDetails} showDisclaimerModal={showDisclaimerModal} handleCloseDisclaimerModal={this.handleCloseDisclaimerModal} handleAgreeButton={this.handleAgreeButton} {...this.props} />
      </Spin>
    );
  }
}

const EventForm = Form.create({ name: "event-form" })(Event);

export default EventForm;
