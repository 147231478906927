import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import * as _ from "lodash";
import { Layout, Menu, Icon } from "antd";
import { getUser } from "../utils/token";

import "./App.scss";
import "antd/dist/antd.css";

import NotFound from "./NotFound";

import Login from "./Login";
// import Reset from "./Reset";

import Home from "./Home";
import Event from "./Event";
import ThankYou from "./ThankYou";
import Scan from "./Scan";
import RaffleViewer from "./RaffleViewer";

moment.updateLocale(moment.locale(), { invalidDate: "N/A" });

const genericErrors = {
  401: {
    title: "Resource not Found",
    message: "Unable to load resource",
  },
  500: {
    title: "Internal Server Error",
    message: "The server was unable to complete your request.",
  },
  502: {
    title: "Bad Gateway",
    message: "The server was unable to complete your request.",
  },
  503: {
    title: "Service Unavailable",
    message: "The server was unable to complete your request.",
  },
};

const paths = [
  // {
  //   default: false,
  //   exact: true,
  //   slug: "admin/reset/:token",
  //   route: "/admin/reset/:token",
  //   label: "Reset",
  //   component: Reset,
  //   display: [""],
  // },
  // {
  //   default: false,
  //   exact: true,
  //   slug: "home",
  //   route: "/",
  //   label: "Home",
  //   component: Home,
  //   display: [""],
  // },
  // {
  //   default: false,
  //   exact: true,
  //   slug: "home",
  //   route: "/home",
  //   label: "Home",
  //   component: Home,
  //   display: [""],
  // },
  {
    default: false,
    exact: false,
    slug: "event-raffle/:event",
    route: "/event-raffle/:event",
    label: "Raffle",
    component: RaffleViewer,
    display: [""],
  },
  {
    default: false,
    exact: false,
    slug: "event/:event",
    route: "/event/:event",
    label: "Event",
    component: Event,
    display: [""],
  },
  {
    default: false,
    exact: false,
    slug: "thank-you",
    route: "/thank-you",
    label: "Thank You",
    component: ThankYou,
    display: [""],
  },

  // {
  //   default: false,
  //   exact: false,
  //   slug: "scan/:event",
  //   route: "/scan/:event",
  //   label: "Scan",
  //   component: Scan,
  //   display: [""],
  // },
  // {
  //   default: false,
  //   exact: true,
  //   slug: "login",
  //   route: "/login",
  //   label: "Login",
  //   component: Login,
  //   display: [""],
  // },
];

const toastXHRError = (code) => {
  const error = genericErrors[code];
  if (error) {
    console.error(error.title, error.message);
  }
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      // selectedEvent: {}
    };

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        switch (error.response.status) {
          case 401 /* signout() */:
            break;
          case 404:
            toastXHRError(404);
            break;
          case 500:
            toastXHRError(500);
            break;
          case 502:
            toastXHRError(502);
            break;
          case 503:
            toastXHRError(503);
            break;
          default:
            break;
        }
        return Promise.reject(error);
      }
    );

    axios.defaults.transformResponse = axios.defaults.transformResponse
      .concat
      // (response) => func(response)
      ();
  }

  // componentDidMount () {
  //   if (isLocalStorageEmpty()) {
  //     this.setState({ isLoggedIn: false })
  //   } else {
  //     this.setState({ isLoggedIn: true })
  //   }
  // }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.loggedIn !== nextProps.loggedIn && nextProps.loggedIn) {
  //     this.setState({ isLoggedIn: true });
  //     // window.location.reload()
  //   }
  // }

  // handleSelectEvent = (event) => {
  //   this.setState({
  //     selectedEvent: event,
  //   });
  // }

  render() {
    const user = getUser();

    return (

      <Layout className="layout">
        <Layout.Content>

          <Layout className="sub-layout">

            <Layout.Content className="sub-layout-content">
              <Switch className="sub-layout_content_switch">
                {window.scrollTo(0, 0)}
                {_.map(paths, (path) => (
                  <Route
                    key={path.slug}
                    exact={path.exact}
                    path={path.route}
                    component={path.component}
                    render={(props) => <path.component {...props} />}
                  />
                ))}
                <Route component={NotFound} />
                <Redirect to="/404" />
              </Switch>
            </Layout.Content>
          </Layout>

          {/* {
            this.props.history.location.pathname !== "" && this.props.history.location.pathname !== "/login" ? (
              // <Footer {...this.props} paths={paths} />
              <Layout.Footer>Footer</Layout.Footer>
            ) : (
              ""
            )
          } */}
        </Layout.Content >
      </Layout >

    );
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.login.loggedIn,
    state: state,
  };
}

export default withRouter(connect(mapStateToProps, {})(App));
