export const slugify = name => {
  return name.replace(/ /g, '-').toLowerCase()
}

export const serialize = (json) => {
  return '?' +
    Object.keys(json).map(function (key) {
      return encodeURIComponent(key) + '=' +
        encodeURIComponent(json[key]);
    }).join('&');
}

export const deserialize = (queryString) => {
  if (queryString.indexOf('?') > -1) {
    queryString = queryString.split('?')[1];
  }
  var pairs = queryString.split('&');
  var result = {};
  pairs.forEach(function (pair) {
    pair = pair.split('=');
    result[pair[0]] = decodeURIComponent(pair[1] || '');
  });
  return result;
}

export const ucFirst = myString => {
  const explode = myString.split(' ')
  let joinString = ''
  explode.forEach(row => {
    joinString += row.slice(0, 1).toUpperCase() + row.slice(1) + ' '
  })

  return joinString
}

export const uuid = () => 'ss-s-s-s-sss'.replace(/s/g, s4)

function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
}

export const tableSorter = (a, b, type = "alphabetical") => {
  if (type === "alphabetical") {
    const x = a.toLowerCase();
    const y = b.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  }

  if (type === "date") {
    const x = (a ? a : "N/A").toLowerCase();
    const y = (b ? b : "N/A").toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  }
}

export const tableSearcher = (searchTerm, searchKeys, collection) => {
  const newArrayObj = collection;
  let distributorArray = [];

  const convertStringDotNotation = (desc, obj) => {
    var arr = desc.split(".");
    while (arr.length && (obj = obj[arr.shift()]));
    return obj;
  };

  newArrayObj.forEach((rowItem, i) => {
    searchKeys.forEach((keys) => {
      let rowItemKeys = convertStringDotNotation(keys, { ...rowItem });
      let isFound =
        rowItemKeys &&
        rowItemKeys.toLowerCase().includes(searchTerm.toLowerCase());
      if (isFound) distributorArray.push(rowItem);
    });
  });

  const newArrayElement = distributorArray.filter(function (
    elem,
    index,
    self
  ) {
    return index === self.indexOf(elem);
  });

  return newArrayElement;
}