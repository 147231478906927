import React, { Component } from "react";
import { Form, Layout, Row, Col } from "antd";
import "./index.scss";
import { RandomReveal } from 'react-random-reveal';
import DOMPurify from "dompurify";
import moment from "moment";

const { Content } = Layout;

class RaffleViewer extends Component {
  initialState = {
    rSettingsData: null,
    eventData: {},
    currentPage: '',
    previousPage: '',
    previousPrize: null,
    currentWinner: null,
    previousWinner: null,
    isRequesting: false,
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  getData = async () => {
    if (this.props.eventData.id && !this.state.isRequesting) {
      this.setState({
        isRequesting: true,
      });
      const currentPage = this.state.currentPage;
      const winner = this.state.currentWinner;
      const onGetParam = {
        event_id: this?.props?.eventData?.id,
      };

      if (currentPage.toLowerCase() == "prize") {
        const prize = this.state.rSettingsData[3] ? JSON.parse(this.state.rSettingsData[3]?.value) : {};
        this.setState({
          previousPrize: prize.prize_image,
          previousWinner: null,
          currentWinner: null,
        });
      }
      if (currentPage.toLowerCase() == 'winner') {
        this.setState({
          previousWinner: winner,
          previousPrize: null
        });
      }
      this.setState({
        previousPage: currentPage,
      });

      await this.props.onGetSettings(onGetParam);

      this.setState({
        isRequesting: false,
      });
    }

  }

  async componentDidMount() {
    const eventName = this.props.match.params.event;
    const params = {
      slug: eventName
    };
    await this.props.onGetEventBySlug(params);

    await this.getData()
    setInterval(() => this.getData(), 1000);
  }

  async componentWillReceiveProps(nextProps) {
    if (this.props.rSettingsData !== nextProps.rSettingsData) {
      const showPage = nextProps.rSettingsData.data[1]?.value
      const winner = JSON.stringify(nextProps.rSettingsData?.data[2]?.value)
      this.setState({
        rSettingsData: nextProps.rSettingsData.data,
        eventData: nextProps.rSettingsData.event_page_details,
        currentPage: showPage,
        currentWinner: winner
      });
    }
  }

  render() {
    const { rSettingsData, eventData, previousPage, previousPrize, currentPage, previousWinner, currentWinner } = this.state
    const { isLoading, } = this.props;

    if (!rSettingsData) {
      return '';
    }

    const showPage = rSettingsData[1]?.value
    const showWinner = rSettingsData[2]?.value
    const showPrize = rSettingsData[3] && JSON.parse(rSettingsData[3]?.value)

    let pageSettings = {};
    let prize = null;
    let winner = null;
    rSettingsData.forEach(row => {
      if (row.name == showPage.toLowerCase().replace(' ', '_') + "_page") {
        pageSettings = row.value;
      }

      if (row.name == "prize") {
        prize = JSON.parse(row.value);
      }

      if (row.name == "winner") {
        winner = row.value;
      }
    });

    let pageContent = pageSettings.content;
    if (prize) {
      pageContent = pageContent.replace("{{prize-name}}", prize.label);
      pageContent = pageContent.replace("{{prize-category}}", prize.name);
      pageContent = pageContent.replace("{{prize-image}}", `<img class='prize-image' src='${process.env.REACT_APP_S3_BUCKET}${prize.prize_image}?${previousPrize != prize.prize_image ? moment().format('x') : ''}' />`);
      pageContent = pageContent.replace("{{prize-description}}", prize.description);
    }

    if (winner) {
      pageContent = pageContent.replace("{{winner}}", '');
    }

    const sanitizeContent = DOMPurify.sanitize(pageContent);

    return (
      <Layout className="raffle-viewer-container"
        style={{
          backgroundColor: pageSettings.background_color ?? '',
          backgroundImage: pageSettings.background_color ? 'url("' + process.env.REACT_APP_S3_BUCKET + pageSettings.background_image + '")' : '',
          height: "100vh",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "100% auto"
        }}>
        <Content>
          <Row type="flex" gutter={30} height={"100%"}>
            <Col span={24} height={"100%"} dangerouslySetInnerHTML={{ __html: sanitizeContent }} />
            {showPage.toLowerCase() === 'winner' ?
              <Col span={24} align="center">
                {winner.map((row, index) => {
                  if (previousWinner == currentWinner) {
                    return (<div style={{ fontSize: "4rem" }} key={index}>{row}</div>)
                  }

                  return (
                    <div style={{ fontSize: "4rem" }} key={index}>
                      <RandomReveal isPlaying duration={1.5} characters={row} />
                    </div>
                  );
                })}
              </Col> : null}
          </Row>
        </Content>
      </Layout >
    );
  }
}

const RaffleViewerForm = Form.create({ name: "raffle-viewer-form" })(RaffleViewer);

export default RaffleViewerForm;
